/*
* If the browser is IE these defined values are used as default
* For other browser these values are being set in ThemeService.js based on the subscription
*/
:root {
  --custom-primary-background-color: #00bf71;
  --custom-primary-text-color: #FFFFFF;
  --custom-secondary-background-color: #00bf71;
  --custom-secondary-background-color-hover: #008f55;
  --custom-secondary-text-color: #FFFFFF;
  --custom-logo: url("/logo_vidsigner_white.png");
}

.App {
  text-align: center;
  height: 100vh;
  max-width: 100%;
}
.container{
  max-width: 100vw;
  padding-right: 0;
}
.col{
  padding: 0;
}

.menu-btn-top, .menu-btn-top:active, .menu-btn-top:visited, .menu-btn-top:focus {
  background-color: #E5E5E5 !important;
  border-color: initial !important;
  border-radius: 0 !important;
  color: #727272;
  box-shadow: none;
  border: none !important;
  margin-top: 0 !important;
  margin-left: 3px;
  padding: 0.6rem;
}
.menu-btn-top:focus{
  background-color: #00BF71 !important;
  color: #FFFFFF;
}
.menu-btn-top:hover{
  background-color: #00BF71 !important;
  color: #FFFFFF;
}

.menu-btn-side, .menu-btn-side:active, .menu-btn-side:visited, .menu-btn-side:focus {
  background-color: #F4F4F4 !important;
  border-color: initial !important;
  border-radius: 0 !important;
  color: #727272;
  box-shadow: none;
  border: none !important;
  width: 100%;
  margin-top: 0 !important;
  padding: 0.6rem;
}
.menu-btn-side:focus{
  background-color: #DADADA !important;
}
.menu-btn-side:hover{
  background-color: #DADADA !important;
}

/*
* Custom .btn-primary button
*/
.btn-primary, .btn-primary:active, .btn-primary:visited, .btn-primary:focus {
  background-color: var(--custom-secondary-background-color) !important;
  border-color: initial !important;
  color: var(--custom-secondary-text-color) !important;
  box-shadow: none;
  border: none;
  min-width: 100px;
}

.btn-primary:hover{
  background-color: var(--custom-secondary-background-color-hover) !important;
}

.reject, .reject:active, .reject:visited, .reject:focus {
  background-color: #86888C !important;
  border-color: initial !important;
  color: #ffffff;
  box-shadow: none;
  border: none;
  min-width: 100px;
}
.reject:hover {
  background-color: #636569 !important;
}

.modal-header {
  background-color: #C4C4C4;
}

.modal-footer {
  justify-content: center;
}

.list-group-item-light {
  background-color: #E5E5E5;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  background-color: #C4C4C4;
}

.row {
  margin-right: 0;
}

/*
* Certificates section
*/
.certificates-container .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: var(--custom-secondary-background-color) !important;
  color: var(--custom-secondary-text-color) !important;
}

.certificates-container .nav-pills .nav-link, .certificates-container svg{
  color: var(--custom-secondary-background-color) !important;
}

.certificates-container .nav-pills .nav-link:hover {
  color: var(--custom-secondary-background-color-hover) !important;
}

/*
* Header background color
*/
.App-header nav.bg-light {
  background-color: var(--custom-primary-background-color) !important;
}

/*
* Header img
*/
.App-header nav .navbar-brand.custom-img
{  
  background-image: var(--custom-logo);
  background-size: contain;
  background-repeat: no-repeat;
  height: 35px;
  max-width: 150px;
  min-width: 125px;
  background-position: center;
}

.App-header nav .navbar-brand {
  padding-bottom: 0px;
}

/*
* Custom documents header table
*/
table thead tr th, table thead tr th svg {
  background-color: var(--custom-secondary-background-color) !important;
  color: var(--custom-secondary-text-color) !important;
}

/*
* Custom documents table page-dropdown
*/
.documents-table-container .page-dropdown #pageDropDown{
  background-color: var(--custom-secondary-background-color) !important;
  color: var(--custom-secondary-text-color) !important;
}

.documents-table-container .page-dropdown ul li.dropdown-item a:hover{
  color: var(--custom-secondary-background-color-hover) !important;
}

/*
* Custom documents table pagination
*/
.documents-table-container .pagination li.active.page-item a.page-link{
  background-color: var(--custom-secondary-background-color-hover) !important;
  color: var(--custom-secondary-text-color) !important;
}

.documents-table-container .pagination li.page-item a.page-link{
  background-color: var(--custom-secondary-background-color) !important;
  color: var(--custom-secondary-text-color) !important;
}

.documents-table-container .pagination li.page-item a.page-link:hover{
  background-color: var(--custom-secondary-background-color-hover) !important;
}

/*
* Custom header logo "by Validated ID" text
*/
.by-validated-id {
  color: var(--custom-primary-text-color) !important;
}

/*
* Custom header text color
*/
.navbar-nav .nav-link {
  color: var(--custom-primary-text-color) !important;;
}

/*
* Custom header menu toggle color
*/
.navbar-toggler {
  border-color: var(--custom-primary-text-color) !important;
  
}

.navbar-toggler:focus {
  outline: unset;
  border: 2px solid var(--custom-primary-text-color) !important;;
}

/*
* Doc Attachments section
*/
.nav-tabs .nav-link.active, .nav-pills .nav-link.active {
  background-color: var(--custom-secondary-background-color) !important;
  color: var(--custom-secondary-text-color) !important;
}

.nav-pills .nav-link:hover {
  color: var(--custom-secondary-background-color-hover) !important;
}

.nav-pills .nav-link {
  color: var(--custom-secondary-background-color) !important;
}

.nav-tabs .nav-link.active .tab-icons {
  filter: brightness(0) invert(1); /* white */
}

@media (max-width: 414px) {
  body {
    font-size: 0.8rem;
  }
  .btn-primary {
    font-size: 0.8rem;
  }
  .reject {
    font-size: 0.8rem;
  }
}
