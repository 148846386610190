.certificates-auth-container {
  width: 25%;
  margin: auto;
  margin-top: 2rem;
}

.certificates-auth-container button ul {
  padding: 0;
}

.list-container-2 {
  margin-bottom: 0;
  padding-left: 0;
}

@media (max-width: 800px) {
  .certificates-auth-container {
    width: 50%;
  }
}
