.header {
  display: flex;
  align-items: center;
}

.change-password-block {
  width: 60%;
  margin: auto;
  margin-top: 2rem;
  background-color: #f1f1f1;
  padding: 50px;
  border-radius: 10px;
}

.subtitle {
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form-container {
  width: 100%;
  margin-bottom: 27px; 
}

.form-container input {
  width: 100%;
  padding: 15px 33px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #C9C9C9;
  font: normal normal medium 16px/19px TT-Norms;
  letter-spacing: 0px;
  color: #A5A5A5;
  box-sizing: border-box;
  margin-bottom: 19px;
}

@media (max-width: 600px) {
  .change-password-block {
    width: 90%;
    padding: 10px;
  }
}

.change-password-header .form-group {
  margin: auto 0 auto auto;
}