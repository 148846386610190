.digital-input-container {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 27px;
}

.digital-input-container .user-input {
  width: 100%;
  padding: 15px 33px;
  height: 51px;
  outline: none;
  border-radius: 2px;
  border: 1px solid #C9C9C9;
  font: normal normal medium 16px/19px TT-Norms;
  letter-spacing: 0px;
  color: #A5A5A5;
  box-sizing: border-box;
}

.digital-input-container .user-input::placeholder {
  color: #A5A5A5;
}

.digital-input-container .user-input:focus {
  border: 1px solid #07BF71;
  color: #151A35;
}

.digital-input-container .user-input:focus::placeholder {
  color: #151A35;
}

.digital-input-container .user-input {
  margin-bottom: 19px;
}

.invalid-login-wrapper {
  text-align: justify;
  color: red;
  font-size: 14px;
}

.advanced span:nth-child(1) {
  margin-right: 5px;
}

.sign-in-regular {
  border: 1px solid #C9C9C9;
  background: white;
  margin-bottom: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  min-width: 150px;
}

.sign-in-regular:hover {
  border: 1px solid #07BF71;
  background-color: #07bf71;
  color: white;
}

.forgot-password-link {
  cursor: pointer;
  margin-bottom: 15px;
}

.new-login-container {
  display: grid;
  grid-template-columns: 3fr 4fr;
  height: 100vh;
}

.new-login-container .hero-container {
  background-size: 100% 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.info-panel {
  border-radius: 13px;
  display: flex;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  height: 85px;
}

.info-panel.panel-1 {
  background: rgba(62, 61, 213, 0.5);
  width: 280px;
  height: 85px;
  position: absolute;
  top: 20vh;
  left: 22vw;
  color: white;
  padding: 2%;
}

.info-panel span {
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.thunder-container {
  min-width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(62, 61, 213, 0.5);
}

.white-thunder-container-panel-1 {
  background: rgba(44, 42, 190, 1);
}

.white-thunder-container-panel-2 {
  background: rgba(44, 42, 190, 0.7);
}

.white-thunder-container-panel-3 {
  background: rgba(6, 1, 79, 1);
}


.info-panel.panel-2,
.info-panel.panel-3 {
  color: white;
}

.info-panel.panel-2 {
  position: absolute;
  width: 276px;
  height: 85px;
  top: 45vh;
  left: 25vw;
  background: rgba(113, 40, 207, 0.74);
}

.info-panel.panel-3 {
  position: absolute;
  width: 260px;
  height: 85px;
  top: 70vh;
  left: 23vw;
  background: rgba(55, 51, 255, 0.6);
}

.social-media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.social-media-container a:hover {
  filter: brightness(0.7);
}

.methods-container {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  place-self: center;
  height: 100%;
}

.methods-container .access {
  font-family: "TT-Norms-Regular";
  color: #7E7E7E;
  font-size: 15px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  margin: 4%;
}

.methods-container .methods-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 5px 0;
  grid-gap: 20px;
  padding: 0 10%;
}

.methods-container .methods-grid .method-card {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #D2D2D2;
  padding: 15px 20px;
  cursor: pointer;
  font-family: TT-Norms;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  height: 55px;
  border-radius: 5px;
  background-color: white;
}

.methods-container .methods-grid .method-card img {
  width: 23.7px;
  height: auto;
}

.methods-container .continue-with-container {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #888;
  font-size: 14px;
  padding: 2%;
}

.methods-container hr {
  width: 150px;
}

.disclaimer-text {
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #7E7E7E;
  margin-top: 10%;
}

.disclaimer-text a {
  color: #7E7E7E;
  font-weight: bold;
}

.copyright {
  margin-top: auto;
  font-family: TT-Norms;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  color: #5A5A5A;
}

.ad-login-input {
  max-width: 440px;
  width: 440px;
  height: 70px;
  padding: 15px 33px;
  outline: none;
  border-radius: 2px;
  border: 1px solid #C9C9C9;
  font: normal normal medium 16px/19px TT-Norms;
  letter-spacing: 0px;
  color: #A5A5A5;
  box-sizing: border-box;
  margin: 10px 0;
}

.ad-sign-in-button {
  max-width: 80%;
  width: 100%;
  height: 55px;
  background: rgba(0, 191, 113, 1);
  font-family: 'TT-Norms-Regular';
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  color: black;
  text-align: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-bottom: 10px;
}

.advanced-options-container {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  margin-bottom: -3%;
}

.advanced-options-container .forgot-password-option {
  font-family: TT-Norms;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #00BF71;
  cursor: pointer;
}

.login-container {
  display: flex;
  height: 100vh;
  position: relative;
}

.login-left {
  flex: 1;
  display: flex;
  align-items: end;
  justify-content: center;
  background-image: url('../../../assets/images/background-new-login.png');
  background-size: cover;
}

.phone-image {
  position: absolute;
  height: 80%;
}

.login-right {
  flex: 1;
  padding: 2rem;
  display: inline-grid;
  flex-direction: column;
  justify-content: center;
}

.logo {
  position: absolute;
  top: 5%;
  left: 20px;
  width: 165px;
  z-index: 10;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.login-btn {
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 1rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.login-btn img {
  width: 30px;
  margin-right: 1rem;
}

.submit-btn {
  background-color: #28a745;
  color: white;
  padding: 1rem;
  border: none;
  margin-top: 1rem;
  font-size: 1rem;
  border-radius: 5px;
}

.submit-btn:hover {
  background-color: #218838;
}

.copyright-new-login {
  font-size: 14px;
  z-index: 10;
  font-family: "TT-Norms-Regular";
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 25px;
}

.methods-header {
  padding: 20px 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: auto;
}

.methods-header .form-group {
  margin: 0;
}

.methods-content {
  margin-top: 10%;
  padding-left: 10px;
  padding-right: 10px;
}

.welcome {
  font-family: TT-Norms-Black;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
}

.methods-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 5px 0;
  grid-gap: 20px;
}

.divider {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  font-family: "TT-Norms-Regular";
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
  width: 30%;
}

.divider::before {
  margin-right: 10px;
}

.divider::after {
  margin-left: 10px;
}

.divider-text {
  color: #888;
  font-size: 14px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 10%;
}

.login-input {
  width: 100%;
  height: 4rem;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
}

.forgot-password {
  text-align: right;
  color: #888;
  font-size: 12px;
  text-decoration: underline;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.saml-sso {
  display: block;
  font-size: 14px;
  color: #28a745;
  text-decoration: underline;
  cursor: pointer;
}

.saml-sso:hover,
.forgot-password:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-left {
    display: none;
  }

  .login-right {
    width: 100%;
    padding: 1rem;
  }

  .logo {
    top: 10px;
    left: 10px;
    width: 120px;
  }

  .login-buttons {
    width: 100%;
  }

  .login-btn {
    width: 100%;
    justify-content: center;
  }

  .login-input {
    width: 100%;
  }

  .submit-btn {
    width: 100%;
  }

  a {
    display: block;
    text-align: center;
  }

  .login-form {
    padding: 0 1%;
  }

  .methods-container .continue-with-container {
    padding: 0;
  }

  .methods-container .methods-grid {
    padding: 0;
  }

  .welcome {
    line-height: 32px;
    font-size: 25px;
  }
}

@media screen and (max-width: 1030px) {
  .info-panel {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .new-login-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .new-login-container .hero-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .digital-input-container .user-input {
    padding: 15px 7px;
  }

  .sign-in-regular {
    min-width: none;
  }

  .button-submit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
}