.recovery-container{
    width: 70%;
    margin: auto;
    margin-top: 2rem;
    background-color: #f1f1f1;
    padding: 50px;
    border-radius: 10px;
}

@media (max-width: 1400px) {
    .recovery-container{
        width: 50%;
    }
}

@media (max-width: 1024px) {
    .recovery-container{
        width: 75%;
    }
}

@media (max-width: 414px) {
    .recovery-container{
        width: 100%;
    }
}