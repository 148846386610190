@font-face {
  font-family: 'TT-Norms';
  src: url(./assets/fonts/TTNorms-Medium_variable.otf);
}

@font-face {
  font-family: 'TT-Norms-Regular';
  src: url(./assets/fonts/TTNorms-Regular-variable.otf);
}

@font-face {
  font-family: 'TT-Norms-Black';
  src: url(./assets/fonts/TTNorms-ExtraBold-variable.otf);
}

body {
  margin: 0;
  height: 100vh;
  font-family:  TT-Norms-Regular ,-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1{
  font-size: 1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}