.App-header{
    /*height: 7vh;*/
}
.bg-light {
    background-color: #00bf71;
    padding: 10px 50px;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 1);
}
.dropdown-menu {
    left: auto;
    right: 0;
}
.dropdown-item a{
    color: #000000;
    text-decoration: none;
    background-color: #ffffff !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #9ea0a3 !important;
}

.user-icon {
    height: 1.5em;
    width: 1.5em;
}

.active-option {
    border-top: 2px solid white;
}

.header-extension {
    background-color: #F2F2F2;
    height: 42px;
    padding: 0 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-extension span {
    font-family: TT-Norms;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #757575;
}

@media (max-width: 600px) {
    .bg-light {
        padding: 10px;
    }

    .header-extension {
        padding: 0 10px;
    }
} 

@media (max-width: 414px) {
    .btn-primary, .btn-primary:active, .btn-primary:visited, .btn-primary:focus {
        min-width: 0;
    }
    .navbar-dark .navbar-toggler {
        z-index: 15;
    }
}
