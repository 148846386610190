.advanced-search-container {
  display: flex;
  flex-direction: column;
  background: #F2F2F2;
  padding: 15px 20px;
  margin-bottom: 15px;
}

.advanced-form {
  margin: 15px 0;
  column-gap: 10px;
}

.inputs-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}

.advanced-form .input-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.advanced-form .input-container label {
  font-size: 18px;
  line-height: 24px;
  font-family: "TT-Norms-Regular";
  font-weight: bold;
  color: #151a35;
  text-align: left;
  margin-right: 10px;
  width: fit-content;
}

.advanced-form select, .advanced-form input {
  width: 100%;
}

.advanced-form input,
.advanced-form select {
  height: 3rem;
  background: #ffffff;
  border: 1.5px solid #AEAEAE;
  box-sizing: border-box;
  border-radius: 2px;
  outline: none;
  color: #151a35;
  font-family: "TT-Norms-Regular";
  font-weight: bold;
  padding-left: 10px;
}

.search-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.search-buttons-container button {
  background: var(--custom-secondary-background-color);
  border-radius: 2px;
  width: 99px;
  height: 38px;
  outline: none;
  font-size: 16px;
  color: white;
  font-family: "TT-Norms-Regular";
  margin: 20px 0 10px 0;
  border: none;
}

.search-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  padding: 3px;
  border: 1.5px solid #AEAEAE;
  box-sizing: border-box;
  border-radius: 6px
}

.search-container:focus {
  border: 1px solid var(--custom-secondary-background-color);
}

.search-container input {
  margin-left: 0;
  width: 100%;
  border-radius: 0 2rem 2rem 0;
  height: 2rem;
  outline: none;
  border: none;
}

.search-icon {
  font-size: 32px;
  color: #AEAEAE;
  background: white;
}

@media (max-width: 1050px) {
  .inputs-container {
    margin: 15px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .advanced-form select,
  .advanced-form input {
    width: calc(100%);
  }
}

@media (max-width: 800px) {
  .advanced-form {
    margin: 15px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    gap: 10px;
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .input-container,
  #date,
  .date-container {
    width: 100%;
  }

  .advanced-form select,
  .advanced-form input {
    width: calc(100% - 100px);
  }

  .advanced-form #date {
    flex-direction: column;
    gap: 10px;
  }

  .advanced-form .input-container label {
    margin-right: 0;
    width: 100px;
  }

  .date-container {
    margin-right: 0;
  }

  .search-buttons-container {
    justify-content: center;
  }
}
