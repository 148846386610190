.modal-header {
  align-items: center;
  gap: 10px;
}

.modal-body-disclaimer {
  background: #F2F2F2;
  border: 1px solid #C4C4C4;
  border-radius: 6px;
  padding: 30px 25px;
  margin: 15px;
}

.centered-buttons-disclaimer button:nth-child(1) {
  border: 2.5px solid #AEAEAE;
  color: #AEAEAE !important;
  background: white !important;
  border-radius: 4px;
  padding: 5px;
}

.centered-buttons-disclaimer button img {
  margin-right: 5px;
}

.centered-buttons-disclaimer button:nth-child(2) {
  border: 2.5px solid #00BF71;
  color: #00BF71 !important;
  background: white !important;
  border-radius: 4px;
  padding: 5px;
}