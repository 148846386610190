.advanced {
  color: #151A35;
  cursor: pointer;
  text-align: left;
}

.with-margin-bottom { 
  margin-bottom: 37px;
}

.advanced-options-select {
  height: 30px;
  outline: none;
  width: auto;
}

.environment-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 17px;
}

.options-container {
  display: flex;
  width: 100%;
  gap: 5px;
}

.options-container button {
  width: 67px;
  height: 27px;
  border: 1px solid #151A35;
  border-radius: 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #090909;
  border-radius: 2px;
  color: #090909;
  outline: none;
}

.options-container .is-environment-selected {
  border: 1px solid #07bf71;
  color:#07bf71;
}