.icon-container {
    width: 80%;
    margin: auto;
    margin-top: 3rem;
    text-align: center;
}


h1, .empty-docs-title {
    display: block;
}

.empty-docs-title {
    margin-top: 2rem;
    font-size: 1.2em;
}

.svg-circle {
    fill: #f4f4f4;
    stroke: none
}

.svg-lines {
    fill: none;
    stroke: var(--custom-secondary-background-color);
    stroke-miterlimit: 10;
    stroke-width: 1.36px;
}

.svg-symbol {
    fill: var(--custom-secondary-background-color);
}