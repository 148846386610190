.validation-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  font-weight: bold;
}

.validation-instructions {
  margin: 10px 0;
  text-align: left;
}

.validation-items-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
}

.validation-item {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.validation-item svg {
  width: 22px;
  height: 22px;
}

.validation-item .validation-text {
  margin-left: 12px;
  text-align: left;
  margin-bottom: 0;
}

@media (max-width: 414px) {
  .validation-items-container {
    grid-template-columns: 1fr;
  }

  .validation-item {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}
