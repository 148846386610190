.profile-container {
  width: 80%;
  margin: auto;
  background-color: #f1f1f1;
  padding: 50px 25px;
  border-radius: 10px;
}
.button-container {
  margin-top: 20px;
}
.signatureCanvas-container {
  padding: 20px 10px 10px;
}
.signature-image-container {
  margin-top: 20px;
}
.signature-image {
  display: block;
  margin: 20px auto;
}
.sigImage {
  background-size: 200px auto;
  width: 200px;
  height: auto;
  background-color: white;
  display: block;
  margin: auto;
}
.error-container {
  background-color: #313131;
  color: #fff;
  padding: 10px;
  max-width: 90%;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}
.collapseButton {
  margin-bottom: 1rem;
}
.input-group-text {
  background-color: #cfcfd1;
  color: #86888c;
  text-transform: uppercase;
}
.form-control {
  color: #86888c;
}
.form-control[readonly] {
  background-color: #cfcfd1;
  border-left: 1px solid #86888c !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #00bf71;
  text-transform: uppercase;
  background-color: #e5e5e5;
  border: 0;
}

.nav-tabs .nav-link {
  color: #86888c;
  background-color: #cfcfd1;
  text-transform: uppercase;
}

.signature-tabs {
  width: 80%;
  display: block;
  margin: 10px auto;
}

.custom-file-input {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .profile-container {
    width: 75%;
  }
}

@media (max-width: 414px) {
  .profile-container, .signature-tabs {
    width: 100%;
    padding: 20px;
  }
}
