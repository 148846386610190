.reset-password-form input {
  width: 100%;
  padding: 15px 33px;
  height: 51px;
  outline: none;
  border-radius: 2px;
  border: 1px solid #C9C9C9;
  font: normal normal medium 16px/19px TT-Norms;
  letter-spacing: 0px;
  color: #A5A5A5;
  box-sizing: border-box;
  margin-bottom: 15px;
}