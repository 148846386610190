/*
* Custom loading
*/
.custom-loading div div {
  background-color: var(--custom-secondary-background-color) !important;
}

.center-vertically {
  height: 75vh;
  display: flex;
  align-items: center;
}