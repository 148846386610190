video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.vid-chain-login-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "TT-Norms";
}

.vid-chain-login-container button:disabled {
  background-color: #151a35!important;
  color: white;
}

.vid-chain-header {
  padding: 57px 66px;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vid-chain-header img {
  max-width: 40vw;
}

.vid-chain-header .form-group {
  margin: 0;
}

.blocks-container {
  display: flex;
}

.login-block {
  background: white;
  width: 494px;
  box-shadow: 0px 0px 12px #00000029;
  padding: 22.5px 23px;
  text-align: start;
  border: 2px solid #fff;
}

.login-icon {
  padding: 0 30px;
}

.login-block:hover,
.login-block:focus-within {
  border: 2px solid #07bf71;
}

.blocks-container .login-block:nth-child(1) {
  margin-right: 31px;
}

.block-header {
  display: flex;
  align-items: center;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.main-title {
  font: normal normal 800 30px/36px TT-Norms-Black;
  letter-spacing: 0px;
  color: #151a35;
}

.title-container .main-subtitle {
  font: normal normal medium 18px/21px TT-Norms;
  letter-spacing: 0px;
  color: #a5a5a5;
}

.blocks-container .login-block:nth-child(2) {
  position: relative;
}

.vid-chain-login-container .blocks-container .sign-in-regular,
.vid-chain-login-container .blocks-container .sign-in-vid-chain {
  padding: 7.5px 15.5px;
  height: 51px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border-radius: 2px;
}

@media (max-width: 1700px) {
  .vid-chain-header {
    padding: 20px;
  }

  .login-block {
    width: 37.5vw;
    min-height: unset;
  }
}

@media (max-width: 1000px) {
  .vid-chain-login-container {
    min-height: 100vh;
    height: auto;
  }

  .block-header {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
  }

  .blocks-container .login-block:nth-child(1) {
    margin-right: unset;
    margin-bottom: 31px;
  }

  .login-icon {
    margin-bottom: 15px;
  }

  .block-header .title-container {
    width: 100%;
  }

  .vid-chain-header {
    padding: 37px;
  }

  .blocks-container {
    flex-direction: column;
    padding: 37px;
    width: 100%;
  }

  .login-block {
    width: 100%;
    padding: 31px;
    height: auto;
  }

  .mobile-hideable {
    opacity: 0;
    height: 0 !important;
    overflow: hidden;
    margin: 0;
  }

  .mobile-show {
    transition: opacity 0.35s ease-out;
    opacity: 1;
    height: auto;
  }

  .vid-chain-login-container .blocks-container .sign-in-regular,
  .vid-chain-login-container .blocks-container .sign-in-vid-chain {
    padding: 15px 7px;
    height: 51px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    border-radius: 2px;
  }

  .title-container .main-title {
    font: normal normal 800 21px/25px TT-Norms-Black;
  }

  .title-container .main-subtitle {
    font: normal normal medium 16px/19px TT-Norms;
  }
}

@media (max-width: 330px) {
  .vid-chain-header {
    padding: 15px 37px;
  }
}

@media (min-height: 750px) and (max-width: 1000px) {
  .more-padding-block {
    padding: 15% 31px;
  }
}

@media (orientation: landscape) {
  .blocks-container {
    margin-top: 55px !important;
  }
}
