.tags-container {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  color: #151a35;
  font-weight: bold;
  margin: 2.5px 5px 2.5px 0;
  padding: 5px 26px;
  border-radius: 26px;
  background: lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

@media (max-width: 800px) {
  .tags-container {
    flex-wrap: unset;
    flex-direction: column;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
