.advanced-switch {
  color: #151A35;
  cursor: pointer;
  text-align: left;
  display: flex;
  gap: 3px;
  font-family: TT-Norms;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
}

.with-margin-bottom { 
  margin-bottom: 37px;
}

.advanced-options-select {
  height: 30px;
  outline: none;
  width: auto;
}

.environment-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 17px;
}

.options-container {
  display: flex;
  width: 100%;
  gap: 5px;
}

.options-container button {
  width: 67px;
  height: 27px;
  border: 1px solid #151A35;
  border-radius: 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #090909;
  border-radius: 2px;
  color: #090909;
  outline: none;
}

.options-container .is-environment-selected {
  border: 1px solid #07bf71;
  color:#07bf71;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #07bf71;
}

input:focus + .slider {
  box-shadow: 0 0 1px #07bf71;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}