.nav-tabs .nav-item {
   margin-bottom: unset;
}

.document-preview-container {
  width: 65%;
  margin: auto;
  display: inline-block;
}
.attachment-container {
  padding-top: 1rem;
  padding-bottom: 0.9rem;
}
.document-preview-header {
  padding: 10px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #34D3996f;
}

.document-preview-header .data-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.document-preview-header .data-group img { 
  width: 35px;
  margin-right: 5px;
}

.document-preview-header .data-group label { 
  font-family: 'TT-Norms-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #151A35;
}

.document-preview-header-2 {
  text-align: left;
}
.document-preview-title {
  font-size: 1.2em;
  font-weight: 400;
  color: #434343;
}
.document-preview-name {
  font-size: 1.5em;
  color: #434343;
}

.capitalize {
  text-transform: uppercase;
}

.check-container {
  display: flex;
  align-items: center;
}

.nav-tabs {
  margin-bottom: 10px;
  border-bottom: none;
}

.nav-tabs .nav-link {
  background: none;
  border-radius: 5px;
  font-family: 'TT-Norms';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #151A35!important;
}

.nav-tabs .nav-link:hover {
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: #D9D9D9!important;
  border-radius: 5px;
  font-family: 'TT-Norms';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #151A35!important;
}

/* Customize the label (the container) */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'TT-Norms-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #151A35;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 3px solid var(--custom-secondary-background-color);
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: white;
  border: 3px solid var(--custom-secondary-background-color);
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: white;
  border: 3px solid var(--custom-secondary-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 7px;
  top: 0px;
  width: 8px;
  height: 15px;
  border: 3px solid var(--custom-secondary-background-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-container label {
  font-family: "TT-Norms";
  margin-left: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #89898a;
}

.document-preview-subcontainer {
  display: flex;
  justify-content: space-between;
}

.document-preview-count {
  font-weight: 500;
  font-size: 1.5em;
}

.bottom-content {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}

.bottom-content-signature-reason {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}

.signature-reason-container {
  display: block;
  position: relative;
}

.toggle-signature-reason {
  height: 50px;
  width: 50px;
  background-color: #8e8e8e;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -25px;
  right: 15%;
  cursor: pointer;
}

.arrow-down-icon {
  width: 15px;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15px;
  top: 0;
}

.signature-reason-title-container {
  height: 30%;
  position: relative;
  background-color: #8e8e8e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sr-title-icon-container {
  width: 70%;
  display: inline-flex;
}

.signature-reason-title {
  color: #e5e5e5;
  font-size: 18px;
  margin: 0;
}

.question-box-icon {
  width: 20px;
  margin-right: 10px;
}

.signature-reason-text-container {
  position: relative;
  height: 70%;
  background-color: #9b9b9b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature-reason-text {
  margin: auto;
  width: 70%;
  text-align: justify;
  color: #e5e5e5;
  vertical-align: middle;
}

.signature-reason-buttons-container {
  background-color: rgba(67, 67, 67, 0.8);
  position: relative;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: rgba(67, 67, 67, 0.8);
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.nav-tabs {
  justify-content: center;
  margin-top: 1rem;
  margin-right: 2rem;
}

.nav-tabs .nav-link {
  width: auto;
  margin-left: 0.15rem;
}

.left-side-menu-col {
  padding-right: 0;
  background-color: #ffffff;
}

.right-side-menu-col {
  padding-left: 0;
  padding-right: 0;
  background-color: #f4f4f4;
}

#signModalButton {
  margin-left: 20px;
}

.action-button {
  min-width: 200px !important;
  min-height: 50px;
}
.back-container {
  display: inline-flex;
  position: absolute;
  cursor: pointer;
  left: 1rem;
}
.next-container{
    display: inline-flex;
    position: absolute;
    cursor: pointer;
    right: 1rem;
}
.back-button{
    margin-right: 0.5rem;
}
.back-button2 {
  height: 2em;
  width: 2em;
  margin-top: 25px;
  display: block;
  color: #434343;
  cursor: pointer;
  border-style: solid;
  border-radius: 25px;
  border-width: 2px;
  padding: 3px;
  position: absolute;
  z-index: 10;
}

.tab-icons {
  max-width: 1.5rem;
  filter: invert(56%) sepia(6%) saturate(195%) hue-rotate(182deg)
    brightness(94%) contrast(89%);
  margin-right: 0.3rem;
}

.tab-hide {
  display: none;
}

.reject-reason {
  min-height: 50px;
  max-height: 100px;
  resize: none;
}

.pdf-container {
  height: 67vh;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid #d0d0d0;
}

.pdf-container-2 {
  height: 77vh;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid #d0d0d0;
}

.pdf-images {
  max-width: 100%;
}

.attached-pdf-images {
  max-width: 100%;
}

.attatchments-side-menu {
  float: left;
  width: 15rem;
  height: 94vh;
  background-color: #f4f4f4;
  z-index: 100;
  position: absolute;
}

.attatchments-container {
  padding-top: 1rem;
}

.signers-table-container {
  width: 65%;
  margin: auto;
  margin-top: 1rem;
  display: inline-block;
}

.side-tab-text {
  font-size: 0.8vw;
}

.signedCellbox {
  padding: 2px;
  background-color: #00bf71;
  min-width: 5.4rem;
  display: inline-block;
  color: #ffffff;
}

.unsignedCellbox {
  padding: 2px;
  background-color: #f4953d;
  min-width: 5.4rem;
  display: inline-block;
  color: #ffffff;
}

.rejectedCellbox {
  padding: 2px;
  background-color: #e04171;
  min-width: 5.4rem;
  display: inline-block;
  color: #ffffff;
}

.undefinedCellbox {
  padding: 2px;
  background-color: #dcdcdc;
  min-width: 5.4rem;
  display: inline-block;
  color: #ffffff;
}

.back-button-text {
  display: inline-block;
}

.rejection-error {
  color: #f4953d;
  margin: 15px 0;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .signature-reason-text {
    margin: auto;
    width: 85%;
    text-align: justify;
    color: #e5e5e5;
    vertical-align: middle;
  }
  .signature-reason-title-container {
    height: 32%;
  }
  .signature-reason-text-container {
    position: relative;
    height: 68%;
    background-color: #9b9b9b;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1024px) {
    .pdf-container{
        height: 74vh;
    }
    .document-preview-container{
        width: 90%;
    }
    .action-button {
        min-height: 60px;
    }
    #signModalButton{
        margin-left: 100px;
    }
    .tab-content3{
        height: 92vh;
    }
    .side-tab-icons {
        margin-right: 0.2rem;
    }
    .side-tab-text {
        font-size: 1vw;
    }
    .nav-link-2 {
        padding: .5rem 0.4rem;
    }
    .nav-tabs {
        margin-right: 0;
    }
    .signers-table-container {
        width: 90%;
    }
    .back-container, .next-container {
        margin-top: 0.4rem;
    }
}

@media (max-width: 936px) {
  .back-button-text {
    display: none;
  }
}

@media (max-width: 768px) {
  .document-preview-header {
    display: flex;
    flex-direction: column;
  }

  .document-preview-header .data-group {
    width: 100%;
  }

  .document-preview-count {
    font-size: 20px;
}

  .pdf-container{
      height: 68vh;
  }
  .back-container {
      position: relative;
      cursor: pointer;
      left: auto;
      margin-top: 1.4rem;
  }

  .back-button {
    margin-right: 0;
  }
  .next-container {
      position: relative;
      cursor: pointer;
      float: right;
      left: auto;
      right: 0;
      margin-top: 1.4rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
  }
  .attachments-row{
      float: left;
  }
  .document-preview-name {
      font-size: 1em;
  }
}

@media (max-width: 540px) {
    .nav-tabs .nav-link {
        width: 100%;
        margin-left: 0;
    }
    .nav-tabs {
        margin-top: 0;
        width: 100%;
    }
    .document-preview-container {
        width: 95%;
    }
    .tab-content3{
        height: 92vh;
    }
    .attachment-container {
        padding-top: 1rem;
        padding-bottom: 0;
        padding-left: 1rem;
    }
    .side-tab-text {
        font-size: 3vw;
    }
    .document-preview-title{
        font-size: 1em;
    }
    .pdf-container{
        height: 44vh;
    }
    .back-container, .next-container {
        margin: 0.5rem;
    }
}

@media (max-width: 414px) {
  .question-box-icon {
    width: 15px;
  }
  .signature-reason-title {
    font-size: 12px;
  }
  .pdf-container {
    height: 50vh;
  }
  .document-preview-header {
    text-align: justify;
  }
  .document-preview-title {
    font-size: 0.8em;
  }
  .document-preview-name {
    font-size: 0.8em;
  }
  .action-button {
    min-width: 150px !important;
    min-height: 45px;
  }
  #signModalButton {
    margin-left: 20px;
  }
  .signedCellbox {
    min-width: 4.4rem;
  }

  .unsignedCellbox {
    min-width: 4.4rem;
  }

  .rejectedCellbox {
    min-width: 4.4rem;
  }

  .undefinedCellbox {
    min-width: 4.4rem;
  }
}

@media (max-width: 375px) {
  .pdf-container {
    height: 45vh;
  }
  .signedCellbox {
    min-width: 3.4rem;
  }

  .unsignedCellbox {
    min-width: 3.4rem;
  }

  .rejectedCellbox {
    min-width: 3.4rem;
  }

  .undefinedCellbox {
    min-width: 3.4rem;
  }
}

@media (max-width: 320px) {
  .pdf-container {
    height: 31vh;
  }
  .action-button {
    min-width: 125px !important;
    min-height: 45px;
  }
}
