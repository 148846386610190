.certificates-container{
    margin: auto;
    padding-left: 20px;
}
.headerClass1{
    width: 40%;
    background-color: #C4C4C4;
}
.headerClass2{
    width: 40%;
    background-color: #C4C4C4;
}
.headerClass3{
    width: 20%;
    vertical-align: middle;
    background-color: #C4C4C4;
}
.table-container{
    width: 75%;
    margin:auto;
}

.keyvault-form-container{
    text-align: left;
}

.kv-header{
    background-color: #C4C4C4;
    padding: 10px;
    padding-left: 30px;
}

.kv-body{
    background-color: #E5E5E5;
    padding: 20px;
    padding-bottom: 5px;
}

table th{
    word-wrap: break-word;
}

table td{
    word-wrap: break-word;
}

.error-container{
    background-color: #313131;
    color: #fff;
    padding: 10px;
    max-width: 100%;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.tab-content2{
    padding: 20px;
    background-color: transparent;
}

.rt-buttons-container{
    display: inline-flex;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #00bf71;
}

a {
    color: #00bf71;
    text-decoration: none;
    background-color: transparent;
}

a:hover{
    color: #008f55;
}

.left-column{
    padding-top: 70px;
}

.right-column{
    background-color: #F4F4F4;
    padding-top: 50px;
    min-height: 100%;
}

.custom-file-input {
    cursor: pointer;
}

@media (max-width: 1024px) {
    .headerClass1{
        width: 33.3%;
    }
    .headerClass2{
        width: 33.3%;
    }
    .headerClass3{
        width: 33.3%;
        vertical-align: middle;
    }
}

@media (max-width: 1024px) {
    .table-container {
        width: auto;
    }
}

@media (max-width: 414px) {
    .table-container {
        width: auto;
    }
    .tab-content {
        padding: 0;
    }
    .left-column{
    }
    .right-column{
        margin-top: 10px;
        height: auto;
    }
}

.form select {
  height: 3rem;
  background: #ffffff;
  border: 3px solid #AEAEAE;
  box-sizing: border-box;
  border-radius: 2px;
  outline: none;
  color: #151a35;
  font-family: "TT-Norms-Regular";
  font-weight: bold;
  padding-left: 10px;
  width: 50%;
}