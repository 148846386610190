.jumbotron {
  padding: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  background: #34D3996f;
}

.jumbotron-content {
  padding: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jumbotron-content p {
  margin: 0;
  font-family: 'TT-Norms-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #151A35;
}

.jumbotron-content img {
  margin-right: 10px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -100px;
  position: absolute;
  width: 100%;
  left: 0;
  background: #5A5A5A;
  padding: 15px 0;
  z-index: 0;
}

.close-jumbotron {
  height: auto;
  width: 1.5em;
  color: #212529;
  cursor: pointer;
  float: right;
  margin-right: 0.3rem;
  margin-top: 0.3rem;
}

.documents-table-container {
  width: 80%;
  margin: auto;
  margin-top: 1rem;
  /*height: 93vh;*/
}

.pagination {
  float: right;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
}

#sign-all-docs {
  display: block;
  margin: auto;
}

.page-dropdown {
  float: left;
}

.documents-table-container .dropdown-menu > li > a {
  display: block;
  background-color: transparent !important;
}

.documents-headerClass1 {
  width: 35%;
}

.documents-headerClass2 {
  width: 30%;
  vertical-align: middle;
}

.documents-headerClass3 {
  width: 20%;
  vertical-align: middle;
}

.toast-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.button-disabled {
  cursor: not-allowed;
}

button[disabled] {
  cursor: not-allowed;
  background-color: #7f7f7f !important;
  pointer-events: none;
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #00bf71 !important;
  border-color: initial !important;
  color: #ffffff;
  box-shadow: none;
  border: none;
}

.btn-secondary:hover {
  background-color: #008f55 !important;
}

.page-link {
  background-color: #00bf71 !important;
  border-color: #ffffff !important;
  color: #ffffff;
}

.page-link:focus {
  box-shadow: none !important;
  border-color: #008f55 !important;
  background-color: #008f55 !important;
}

.page-link:hover {
  color: #ffffff;
  background-color: #008f55 !important;
}

.page-item.active .page-link {
  color: #ffffff;
  background-color: #008f55 !important;
}

.refresh-button {
  height: 1.5em;
  margin: auto;
  display: block;
  width: 1.5em;
  color: #ffffff;
  cursor: pointer;
  border-style: solid;
  border-radius: 25px;
  border-width: 2px;
  padding: 3px;
}

.side-tab {
  text-align: left;
}

.nav-link-2 {
  word-break: break-all;
}

.side-tab-icons {
  max-width: 1.5rem;
  filter: invert(56%) sepia(6%) saturate(195%) hue-rotate(182deg)
    brightness(94%) contrast(89%);
  margin-right: 1rem;
}

.nav-pills .nav-link.active .side-tab-icons {
  filter: brightness(0) invert(1);
}

.react-bootstrap-table th[data-row-selection] {
  vertical-align: middle;
  width: 5% !important;
}

.search-bar {
  border-radius: 2rem;
}

tbody tr td:nth-child(3) {
  min-width: 100px;
}

th {
  background-color: #00bf71;
  color: #ffffff;
}

.table-hover tbody tr:hover {
  background-color: #00000026;
}

.btn-table {
  font-size: 1rem !important;
}

.list-container {
  margin-bottom: 0;
}

.filter-list-container {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.pagination {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.loading-more-container {
  position: absolute;
  bottom: 10vh;
  z-index: 10;
  width: auto;
  height: auto;
  left: 0;
  right: 0;
}

@media (max-width: 1024px) {
  .documents-headerClass1 {
    width: 35%;
  }
  .documents-headerClass2 {
    width: 30%;
  }
  .documents-headerClass3 {
    width: 20%;
    vertical-align: middle;
  }
}

@media (max-width: 800px) {
  .btn-table {
    min-width: unset;
  }
}

@media (max-width: 600px) {
  .documents-table-container {
    width: 95%;
  }

  .documents-headerClass1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-bootstrap-table {
    overflow-x: auto;
  }

  .react-bootstrap-table table {
    table-layout: unset !important;
  }

  tbody tr td:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  } 
}

@media (max-width: 414px) {
  .btn-table {
    font-size: 0.7rem !important;
  }
}