.bar-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.advanced-search {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #151a35;
  cursor: pointer;
  width: 272px;
  height: 52px;
  border: 3px solid var(--custom-secondary-background-color);
  border-radius: 6px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.advanced-search:focus {
  outline: none;
}

.advanced-search span:nth-child(1) {
  padding-left: 15px;
}

.advanced-search div:nth-child(2) {
  background-color: var(--custom-secondary-background-color);
  height: 110%;
  padding: auto;
  width: 50px;
  display: flex;
}

.advanced-search div:nth-child(2) img {
  width: 20px;
  display: block;
  margin: auto;
}

@media (max-width: 800px) {
  .bar-container {
    justify-content: center;
  }

  .advanced-search {
    font-size: 1.25rem;
  }
}
